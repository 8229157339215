





































import { Observer } from 'mobx-vue'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Observer
@Component({
  components: {},
})
export default class OgCard extends Vue {
  @Prop({ default: {} }) ogInfo!: any
  @Prop({ default: false }) col!: boolean
}
